<template>
    <div class="container">
        <div class="row">
            <div class="col s12 left-align">
                <h3>{{ $t("picture.CaricareLogo") }}</h3>
              <p v-html="$t('picture.CaricareLogoText')"></p>
            </div>

            <div class = "col s12 blue-grey darken-3 divide" ></div>

            <div class="col s12 l7 left-align">
                
                <h6>{{ $t("picture.CaratteristicheFoto") }}</h6>

                <ul class="browser-default">
                    <li>
                      {{ $t("picture.CaratteristicheFormato") }}
                    </li>
                    <li>
                      {{ $t("picture.CaratteristichePeso") }}
                    </li>
                    <li>
                      {{ $t("picture.CaratteristicheDimensioni") }}
                    </li>
                    <li>
                      {{ $t("picture.CaratteristicheDefinizione") }}
                    </li>
                    <li>
                      {{ $t("picture.CaratteristicheSfondo") }}
                    </li>
                    <li>
                      {{ $t("picture.CaratteristicheLimitare") }}
                    </li>
                </ul>

            </div>

            <div class="col s12 l5 center">
                <img src="@/assets/img/logo-prueba.png" 
                    alt="TradeMarke - Public Register" 
                    class = 'example'
                >
            </div>

        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style scoped>
    h6{
        font-weight: bold;
    }
    .example{
        max-width: 100%;
        margin-top: 40px;
        margin-bottom: 150px;
    }

    .divide{
        height: 2px;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    @media screen and (max-width: 600px){
        .example{
            margin-bottom: 30px;
        }
    }

</style>
